import React from 'react';
import {
  Container, Row, Col,
} from 'reactstrap';
import { Link } from 'gatsby';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Section from '../../components/Section/Section';
import Headline from '../../components/Headline/Headline';

const LeistungenPage = () => (
  <Layout headerWhite>
    <SEO title="Praxis - Dr. Burkhard Schubert" />
    <div className="greenContainer">
      <ul>
        <li><Link to="/praxis">Unsere Praxis</Link></li>
        <li className="active"><Link to="/praxis/dr-burkhard-schubert">Dr. Burkhard Schubert</Link></li>
        <li><Link to="/praxis/dr-kathrin-kostka">Dr. Kathrin Kostka</Link></li>
      </ul>
    </div>
    <Section dark>
      <Row noGutters>
        <Col xs={12} lg={6}>
          <div className="fullWidth-col-image" />
        </Col>
        <Col xs={12} lg={6}>
          <div className="fullWidth-col-content right">
            <Headline light>Dr. Burkhard Schubert</Headline>
            <p>
              <ul>
                <li>seit 1999 niedergelassener Orthopäde und bis 2020 in Gemeinschaft mit Dr. Rosenthal in einer  renommierten Praxisklinik in Bochum mit vorwiegend operativen Behandlungsspektrum tätig</li>
                <li>Erfahrung in über 15.000 operativen Behandlungsverläufen</li>
                <li>über 100.000 durchgeführte Gelenkinjektionen und Punktionen</li>
                <li>Kompetenz - Erfahrung - gutes Netzwerk</li>
              </ul>
            </p>
          </div>
        </Col>
      </Row>
    </Section>
    <Section>
      <Container>
        <Headline>Zusatzbezeichnungen und Zertifizierungen</Headline>
        <Row>
          <Col xs={12}>
            <ul>
              <li>Akupunktur</li>
              <li>Chirotherapie/ Manuelle Medizin</li>
              <li>Magnetresonanztomographie (MRT) -fachgebunden - Orthopädie</li>
              <li>Fachkunde Strahlenschutz Röntgendiagnostik des gesamten Skelettes</li>
              <li>Stosswellentherapie</li>
              <li>Triggerpunkttherapie</li>
              <li>Kinesiotaping</li>
              <li>Mesotherapie</li>
              <li>Psychosomatische Grundversorgung</li>
            </ul>
            <br />
            <br />
            <Headline>Mitgliedschaften</Headline>
            <ul>
              <li>
                <b>BVOU:</b>
                {' '}
                Berufsverband der Fachärzte für Orthopädie und Unfallchirurgie
              </li>
              <li>
                <b>DGMSM:</b>
                {' '}
                Deutsche Gesellschaft für Muskuloskelettale Medizin e.V. (Manuelle Medizin)
              </li>
              <li>
                <b>FACM:</b>
                {' '}
                Forschungsgruppe Akupunktur
              </li>
              <li>
                <b>IGOST:</b>
                {' '}

                Interdisziplinäre Gesellschaft für orthopädische/ unfallchirurgische und allgemeine
                Schmerztherapie e.V.
              </li>
              <li>
                <b>DGM:</b>
                {' '}
                Deutsche Gesellschaft für Mesotherapie e.V .
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default LeistungenPage;
